/* eslint-disable no-unused-vars */
/* eslint-disable no-new */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React, {
  useEffect, useState, Suspense, lazy,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Flex,
  Headline1,
  Headline2,
  Section,
  Paragraph,
} from '../../components/common/styled.components';
import { CustomButton } from '../../components/button';
import ContactForm from '../../components/form';
import useScrollY from '../../components/hooks/useScrollY';
import Modal from '../../components/modal';
import useDetectMobile from '../../hooks/useDetectMobile';

import MainBackground from '../../img/bodyBg.png';
import reviewImg from '../../img/reviewBackground.avif';
import {
  AboutFlex,
  InNumbersWrapper, IntroductionSection, MainSection, ReviewSection,
} from './styled.components';
import Layout from '../../components/layout';
import CustomLoader from '../../components/loader';
import fetchPageContext from '../../api/fetchPageContext';

const GallerySection = lazy(() => import('../../components/gallery'));
const ServicesComponent = lazy(() => import('./services'));

export default function MainPage() {
  const { t, i18n } = useTranslation();
  const detectMobile = useDetectMobile();
  const currentLang = i18n.language;

  const [galleryContent, setGalleryContent] = useState();
  const [reviews, setReviews] = useState([{
    content: '',
    name: '',
    file: '',
  }]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageContext, setPageContext] = useState({
    title: '',
    subtitle: '',
    content: '',
  });
  const [clickedIndex, setClickedIndex] = useState();
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  useEffect(() => {
    const galleryElement = document.getElementById('gallery');
    const anchorElements = galleryElement.querySelectorAll('.gallery-item');

    const handleMouseOver = (event) => {
      try {
        const hoveredElement = event.target;
        const index = Array.from(anchorElements).indexOf(hoveredElement);
        setClickedIndex(index + 1);
        return index;
      } catch (err) {
        return err;
      }
    };

    const handleClick = async (event) => {
      try {
        const clickedElement = event.target;
        const index = Array.from(anchorElements).indexOf(clickedElement);
        setClickedIndex(index + 1);
        return index;
      } catch (err) {
        return err;
      }
    };

    const isInsideModal = (element) => {
      let parent = element.parentElement;
      while (parent) {
        if (parent.id === 'modal') {
          return true;
        }
        parent = parent.parentElement;
      }
      return false;
    };

    anchorElements.forEach((element) => {
      if (!isInsideModal(element)) {
        element.addEventListener('click', handleClick);
        element.addEventListener('mouseover', handleMouseOver);
      }
    });

    return () => {
      anchorElements.forEach((element) => {
        if (!isInsideModal(element)) {
          element.removeEventListener('click', handleClick);
          element.removeEventListener('mouseover', handleMouseOver);
        }
      });
    };
  });

  useEffect(() => {
    const handleCloseModal = () => {
      setIsModalVisible(false);
    };
    const closeModalElement = document.getElementById('close');
    if (closeModalElement) {
      closeModalElement.addEventListener('click', handleCloseModal);
    }
  }, [isModalVisible]);

  useEffect(() => {
    fetchPageContext(setPageContext, currentLang, { pl: 28, en: 173 });
  }, [currentLang]);

  useEffect(() => {
    const setReviewContent = async () => {
      try {
        const filteredReviews = Object.values(pageContext).filter((obj) => obj.hasOwnProperty('content') && obj.content.trim() !== '');
        setReviews(filteredReviews);
      } catch (error) {
        return error;
      }
      return '';
    };

    const delayedExecution = () => new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 333);
    });

    const fetchDataWithDelay = async () => {
      await delayedExecution();
      await setReviewContent();
    };

    fetchDataWithDelay();
  }, [pageContext, currentLang]);

  const toggleScrolled = () => {
    document.getElementById('main').classList.add('scrolled');
    if (document.documentElement.scrollTop === 0) document.getElementById('main').classList.remove('scrolled');
  };

  const countExperience = () => {
    const startDate = 2000;
    const currDate = new Date();
    return currDate.getFullYear() - startDate;
  };

  const toggleModal = async () => {
    if (!detectMobile) {
      setIsModalVisible(!isModalVisible);
    }
  };

  useScrollY(toggleScrolled, 200);

  function fadeInOnScroll() {
    const elements = document.querySelectorAll('.fade-in');

    function checkScroll() {
      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (elementTop + 400 < windowHeight) {
          element.classList.add('show');
        }
      });
    }
    checkScroll();
    window.addEventListener('scroll', checkScroll);
  }
  fadeInOnScroll();

  useEffect(() => {
    const fetchGalleryContent = async () => {
      try {
        let response;
        if (currentLang === 'pl') {
          response = await fetch(
            'https://cms.actman.pl/wp-json/wp/v2/posts/91?_embed',
          );
        }
        if (currentLang === 'en') {
          response = await fetch(
            'https://cms.actman.pl/wp-json/wp/v2/posts/91?_embed',
          );
        }
        const data = await response.json();
        const galleryObject = await data._embedded['acf:attachment'];
        const parsedGalleryObject = [];
        await galleryObject.map((item) => parsedGalleryObject.push({
          id: item.id,
          title: item.title.rendered,
          caption: item.caption.rendered,
          url: item.source_url,
          alt: item.alt_text,
        }));
        setGalleryContent(parsedGalleryObject.splice(0, parsedGalleryObject.length -= parsedGalleryObject.length % 4));
        return parsedGalleryObject;
      } catch (e) {
        return e;
      }
    };
    fetchGalleryContent();
  }, []);

  return (
    <>
      {!pageContext && <CustomLoader />}
      <Layout metaTitle={t('main-meta-title')}>
        <MainSection id="main" bgimg={MainBackground}>
          <Container
            align="center"
            justify="center"
            className="main"
            direction="column"
          >
            <Flex
              direction="row"
              justify="space-between"
              align="center"
            >
              <Headline1>
                {t('main-headline')}
              </Headline1>
            </Flex>
            <CustomButton id="main-btn" to="#contact" scrollTo="contact" className="black bordered main">
              {t('lets-talk')}
              {' '}
              ↓
            </CustomButton>
          </Container>
        </MainSection>
        <IntroductionSection className="fade-in">
          <Container>
            <Flex align="flex-start" justify="space-between">
              <Flex width="300px">
                <Headline2 className="subtitled">
                  {t('hello')}
                  {' 👋'}
                </Headline2>
              </Flex>
              <Flex width="876px" justify="flex-end">
                <Paragraph className="h3css" alignText="left" dangerouslySetInnerHTML={{ __html: pageContext.intro }} />
              </Flex>
            </Flex>
          </Container>
        </IntroductionSection>
        <Suspense fallback={<>Loading...</>}>
          <ServicesComponent className="fade-in" />
        </Suspense>
        <Section id="about" className="fade-in">
          <Container direction="column">
            <AboutFlex direction="column" gap="20px;">
              <Headline2 className="subtitled">{t('in-services')}</Headline2>
              <Paragraph className="h3css" alignText="left">
                {pageContext.about}
              </Paragraph>
              <InNumbersWrapper justify="space-between">
                <Flex direction="column">
                  <Headline1 className="inNumbers">
                    {`${countExperience()} ${t('years')}`}
                  </Headline1>
                  <Paragraph className="extra-large">
                    {t('in-numbers-age')}
                  </Paragraph>
                </Flex>
                <Flex direction="column">
                  <Headline1 className="inNumbers">
                    120 000 m²
                  </Headline1>
                  <Paragraph className="extra-large">
                    {t('in-numbers-square')}
                  </Paragraph>
                </Flex>
                <Flex direction="column">
                  <Headline1 className="inNumbers">
                    1800+
                  </Headline1>
                  <Paragraph className="extra-large">
                    {t('in-numbers-spaces')}
                  </Paragraph>
                </Flex>
              </InNumbersWrapper>
            </AboutFlex>
          </Container>
        </Section>
        <ReviewSection id="reviews" bgimg={reviewImg} className="fade-in reviews">
          <Container>
            <Suspense fallback={t('loading')}>
              <GallerySection
                itemsPerPage={1}
                content={reviews}
                isReviews
                style="reviews"
              />
            </Suspense>
          </Container>
        </ReviewSection>
        <Section id="gallery">
          <Container>
            {galleryContent && (
            <>
              <GallerySection
                itemsPerPage={detectMobile ? 4 : 8}
                content={Object.values(galleryContent)}
                toggleModal={() => toggleModal()}
                showMoreOnClick={() => setShowMoreClicked(true)}
              />
              {isModalVisible && clickedIndex && (
              <Modal
                modalBody={(
                  <GallerySection
                    setCustomIndex={clickedIndex}
                    detectMobile={detectMobile}
                    itemsPerPage={!detectMobile ? 1 : 1}
                    style="modal"
                    toggleModal={() => toggleModal()}
                    content={Object.values(galleryContent)}
                  />
                )}
              />
              )}
            </>
            )}
          </Container>
        </Section>
        <ContactForm />
      </Layout>
    </>
  );
}
