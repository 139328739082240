/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { respondTo } from '../../util/breakpoints';

export const ColorPalette = {
  black: '#000000',
  neutral: '#F0F0F0',
  blackTransparent: 'rgba(0, 0, 0, 0.5)',
  white: '#FFFFFF',
  primary: '#E5C299',
  hoverOverlayBlack: '#1B1B1B',
  pressedOverlayBlack: '#2B2B2B',
  hoverOverlayWhite: '#EBEBEB',
  pressedOverlayWhite: '#DBDBDB',
};

export const Spacing = {
  pt8: '8px',
  pt24: '24px',
  pt32: '32px',
  pt40: '40px',
  pt48: '48px',
  pt64: '64px',
  pt80: '80px',
  pt160: '160px',
  pt220: '220px',
};

export const TextCss = css`
  font-family: "Satoshi";
  font-style: normal;
`;

export const ContainerCss = css`
  box-sizing: border-box;
  padding: 0 60px;
  width: 100%;
  margin: auto;
  max-width: 1920px;
  ${respondTo.mobile`
    flex-basis: 100%;
    width: 100%;
    padding: 0 18px;
  `}
`;

export const Container = styled.div`
  &.page-template {
    padding-top: 0px;
    > * h1 {
      margin: 48px 0;
    }
  }
  ${ContainerCss}
  height: ${(props) => props.height || 'auto'};
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'flex-start'};
  &.main {
    > * h1 {
      ${respondTo.mobile`
        font-size: 24px;
        &::first-word {
          font-size: 64px;
        }
        line-height: 1.5em;
      `}
      color: ${ColorPalette.white} !important;
    }
    ${respondTo.mobile`
      justify-content: flex-start !important;
      padding: 24px 24px 24px 24px;
    `}
  }
`;

const Headline1Css = css`
  ${TextCss}
  font-weight: 700;
  font-size: 104px;
  overflow-x: hidden;
  line-height: 145px;
  text-transform: uppercase;
  word-break: break;
`;

export const Headline1 = styled.h1`
  ${Headline1Css}
  width: ${(props) => props.width || '100%'};
  overflow-x: visible;
  
  & > strong {
    color: ${ColorPalette.primary};
  }
  &.h2css {
    font-size: 81px;
    line-height: 120%;
  }
  &.inNumbers {
    font-size: 81px;
    ${respondTo.xsdesktop`
      font-size: 64px;
    `}
    text-transform: none;
    > span {
      font-size: 48px;
    }
  }
  ${respondTo.xsdesktop`
    font-size: 72px;
    line-height: 90px;
    padding-bottom: -48px;
    padding-top: 36px;
    margin: auto;
    align-self: center;
    > * p {
      margin-top: 12px !important;
    }
  `}
  ${respondTo.beforemobile`
    font-size: 48px;
    &.h2css {
      font-weight: 400;
      word-break: break-word;
      font-size: 32px;
      line-height: 110%;
    }
  `}
`;

export const Headline2Css = css`
  ${TextCss}
  font-weight: 400;
  font-size: 80px;
  line-height: 110%;

  ${respondTo.mobile`
    font-size: 40px;
  `}
`;

export const Headline2 = styled.h2`
  &.subtitled {
    font-size: 48px;
    text-align: left !important;
    justify-self: flex-start;
    align-self: flex-start;
    
    ${respondTo.xsdesktop`
      font-size: 32px;
    `}
    ${respondTo.mobile`
      font-size: 32px;
      text-align: left;
    `}
  }
  ${Headline2Css}
  &.extra-large{
    margin-bottom: 80px;
    height: auto;
    margin-top: 0;
    text-transform: capitalize !important;
    ${Headline1Css}
    overflow-y: hidden;
    ${respondTo.mobile`
      font-size: 28px;
      line-height: normal;
    `}
  }
  &.paragraph-style {
    ${TextCss}
    margin-bottom: 48px;
    font-size: 21px;
    text-align: left;
    font-weight: 400;
  }
  ${respondTo.mobile`
    &.subtitled {
      font-size: 28px;
    }
    &.mobile-padding {
      padding-top: 48px;
      margin-bottom: 12px;
    }
  `}
`;

export const SubtitleCss = css`
  ${TextCss}
  font-weight: 400;
  font-size: 40px;
  line-height: 145%;
  margin-bottom: -12px;
  &.title {
    font-size: 48px;
  }
  ${respondTo.mobile`
    font-size: 32px;
    &.small {
      font-size: 13px;
    }
  `}
`;

export const Subtitle = styled.h3`
  ${SubtitleCss}
  &.servicePage {
    text-align: left;
    justify-self: flex-start;
    ${respondTo.mobile`
      width: 100%;
      font-size: 32px !important;
    `}
    margin-bottom: 32px;
  }
  text-align: ${(props) => props.alignText || 'center'};
`;

export const Paragraph = styled.p`
  > p {
    margin: 0; 
  }
  &.h3css {
    ${SubtitleCss}
    ${respondTo.mobile`
      font-size: 24px;
    `}
  }
  &.review {
    font-size: 60px;
    ${respondTo.mobile`
      font-size: 24px;
    `}
  }
  &.mobile-padding {
    padding-top: 48px;
  }
  > * li {
    ${TextCss}
    font-size: 21px;
    padding-bottom: 8px;
    ${respondTo.mobile`
      font-size: 16px;
    `}
  }
  > h2 {
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 600 !important;
  }
  ${TextCss}
  text-align: left;
  font-weight: 400;
  font-size: ${(props) => (
    props.size === 's'
      ? '21px'
      : props.size === 'm'
        ? '24px'
        : '28px')};
  line-height: 110%;

  ${(props) => props.italic && 'font-style: italic; font-weight: 300;'}

  ${respondTo.mobile`
    &.content {
      font-size: 21px;
    }
    font-size: 18px;
    &.small {font-size: 14px;}
    &.medium {font-size: 16px;}
  `}

  &.extra-large {
    ${SubtitleCss}
    ${respondTo.mobile`
      width: 100%;
      font-size: 24px !important;
      margin-bottom: 0;
    `}
  }
  &.headline2-styled {
    ${Headline2Css}
  }
`;
export const Copyright = styled.span`
  width: auto;
  ${respondTo.mobile`
    width: 100%;
    text-align: center;
    padding-top: ${Spacing.pt48};
  `}
`;

export const FooterHeadline = styled.h4`
  ${respondTo.mobile`
    font-size: 18px;
  `}
`;

export const Flex = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'flex-start'};
  flex-wrap: ${(props) => props.wrap || 'default'};
  width: ${(props) => props.width || '100%'};
  max-width: 100%;
  height: ${(props) => props.heightProp || 'auto'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  gap: ${(props) => props.gap || '0'};
  ${respondTo.mobile`
    width: ${(props) => props.mobileWidth || '100%'}
  `}
  > * {
    box-sizing: border-box;
    flex-basis: ${(props) => props.flexBasis || 'none'};
    ${respondTo.mobile`
      flex-basis: 100%;
    `}
  }
  ${respondTo.mobile`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

export const OnLoadComponent = styled.div`
  width: calc(100% * ${(props) => props.randomWidth});
  border-radius: 16px;
  height: ${(props) => props.height || '100px'};
  background: grey;
  opacity: 0.2;
  margin-bottom: 45px;
  animation: infinite 987ms pulse;
  @keyframes pulse {
    0% {
      opacity: 0.2;
    } 50% {
      opacity: 0.1;
    } 100% {
      opacity: 0.2;
    }
  }
`;

export const Section = styled.section`
  background-image: url(${(props) => props.bgimg || ''});
  background-repeat: no-repeat;
  margin: auto;
  width: 100vw;
  overflow-x: none;
  padding-top: ${Spacing.pt160};
  transition: background-size 2s .2s ease-out;
  &#about {
    height: auto;
  }
  &.fade-in {
    > * {
      opacity: 0;
      transform: translateY(50px);
      transition: opacity 610ms, transform 987ms;
    }
  }

  &.fade-in.show {
    > * {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${respondTo.mobile`
    width: 100vw;
    padding: ${Spacing.pt8} 0;
  `}
  &#services {
    > * ${Headline2} {
      margin-left: 18px;
      margin-bottom: 24px;
    }
    > ${Container} {
      ${respondTo.mobile`
        padding: 0 !important;
      `}
    }
    ${respondTo.mobile`
      > * ${Paragraph} {
        padding: 0 24px;
      }
    `}
  }
`;
