import styled, { keyframes } from 'styled-components';

const Spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const Ease = keyframes`
  0% { opacity: 0; background-color: transparent; }
  50% { opacity: 1; background-color: rgba(255, 255, 255, 0.4); }
  100% { opacity: 1; background-color: white; }
`;

export const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 200;
  content: '';
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  animation: ${Ease} 144ms cubic-bezier(.7,.01,.06,.26);
`;

export const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid #000000;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${Spin} 987ms linear infinite, ${Ease} 144ms cubic-bezier(.43,.06,.9,-0.13);
`;
