import styled, { css, keyframes } from 'styled-components';
import {
  ColorPalette, Flex, Headline2Css, Section, Spacing, TextCss,
} from '../common/styled.components';
import { respondTo } from '../../util/breakpoints';

const TransitionCSS = css`
  transition: all;
  transition-duration: 233ms;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 233ms;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-duration: 233ms;
  -moz-transition-property: all;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-duration: 233ms;
  -ms-transition-property: all;
  -ms-transition-timing-function: ease-in-out;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FormSection = styled(Section)`
  background-color: ${ColorPalette.black};
  color: ${ColorPalette.white};
  margin-top: ${Spacing.pt160};
  padding-bottom: 160px;
  padding-top: 120px;
  ${respondTo.mobile`
    padding: 80px 0;
    margin-top: 60px;
  `}
`;

export const StyledInput = styled.input`
  ${TextCss}
  font-weight: 400;
  max-width: 100%;
  width: 100%;
  &:not(:placeholder-shown) {
    margin-top: 30px;
  }
  
  ${respondTo.mobile`
    font-size: 18px;
    margin-top: 10px;
    max-width: 100%;
  `}
  height: 40px;
  background-color: transparent;
  color: white;
  border:none;
  border-bottom: 1px solid white;
  box-shadow: none;
  top: 0;
  left: 0;
  ${TransitionCSS}
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 21px;
  &.error {
    border-color: #DA0D0D;
    color: #DA0D0D;
  }
  &::placeholder { 
    visibility: none;
    font-size: 0px;
    color: white;
  }
  &:focus::placeholder {
    ${TextCss}
    ${TransitionCSS}
    font-weight: 400;
    font-size: 21px;
    ${respondTo.mobile`
      font-size: 18px;
    `}
    line-height: 110%;
    color: white;
  }
  &.full {
    margin-top: 30px;
  }
  &:focus {
    margin-top: 30px;
    ${respondTo.mobile`
      margin-top: 42px;
      padding-bottom: 10px;
    `}
    position: relative;
    outline: none !important;
    box-shadow: none !important;
  }
  &.message {
    max-width: 100%;
  }
  &.temat {
    max-width:100%;
  }
  &[type="checkbox"] {
    width: 22px;
    justify-self: center;
    align-self: center;
    flex-shrink:0;
    &:not(:placeholder-shown) {
      margin: 15px 10px 15px 0;
    }
    ${respondTo.mobile`
      width: 21px !important;
          &:not(:placeholder-shown) {
            margin-top: 35px;
            margin-bottom: 0px;
            margin-right: 0px;
            margin-left: 10px;
    }
    `}
  }
`;
export const StyledTextarea = styled.textarea`
  ${TextCss}
  &:not(:placeholder-shown) {
    margin-top: 30px;
  }
  &.error {
    border-color: #DA0D0D;
    color: #DA0D0D;
  }
  width: 100%;
  font-weight: 400;
  &.full {
    margin-top: 30px;
  }
  ${respondTo.mobile`
    font-size: 18px;
  `}
  height: 40px;
  font-size: 21px;
  background-color: transparent;
  color: white;
  border:none;
  border-bottom: 1px solid white;
  box-shadow: none;
  position: relative;
  top: 0;
  left: 0;
  ${TransitionCSS}
  margin-top: -10px;
  margin-bottom: 15px;
  &:focus {
    margin-top: 40px;
    padding-bottom: 200px;
    ${respondTo.mobile`
      padding-bottom: 100px;
    `}
    overflow-y: hidden;
    position: relative;
    outline: none !important;
    box-shadow: none !important;
  }
    &::placeholder { 
    visibility: none;
    font-size: 0px;
    color: white;

  }
  &:focus::placeholder {
    ${TextCss}
    font-weight: 400;
    font-size: 21px;
    line-height: 110%;
    color: white;
    ${respondTo.mobile`
      font-size: 18px;
    `}
  }
  &.message {
    max-width: 100%;
  }
`;

export const StyledSelect = styled.select`
  ${TextCss}
  max-width: 429px;
  font-weight: 400;
  ${respondTo.mobile`
    font-size: 18px;
    padding-bottom: 10px;
  `}
  width: 100%;
  height: 40px;
  font-size: 21px;
  background-color: transparent;
  color: white;
  border:none;
  border-bottom: 1px solid white;
  box-shadow: none;
  position: relative;
  top: 0;
  left: 0;
  ${TransitionCSS}
  margin-top: -10px;
  text-transform: uppercase;
  margin-bottom: 15px;
  &:focus {
    outline: none;
  }
  > option {
    display: block;
    font-weight: 400;

    background-color: white;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding: 8 16 16 16 !important;
  }
`;
export const StyledOption = styled.option`
  ${TextCss}
  color: black !important;
  font-size: 21px;
  outline: none;
`;

export const StyledLabel = styled.label`
  ${TextCss}
  font-weight: 400;
  ${respondTo.mobile`
    font-size: 18px;
    text-align: left;
    justify-self: flex-start;
    width: 100%;
  `}
  font-size: 21px;
  line-height: 110%;
  color: #FFFFFF;
  margin-bottom: -20px;
  text-transform: uppercase;
  &.lowercase {
    text-transform: none;
  }
  > * strong {
    text-decoration: underline;
    margin-bottom: 3px;
  }
`;

export const StyledCheckbox = styled.input`
  ${TextCss}
  font-weight: 400;
  height: 25px !important;
  width: 25px !important;
  margin-right: 10px;
  background-color: black;
  font-size: 21px;
  line-height: 110%;
  ${respondTo.mobile`
    font-size: 16px;
  `}
  color: #FFFFFF;
  > * a {
    color: #FFFFFF;
    text-decoration: none !important;
  }
  &.error {
    border-bottom: 1px red solid;
  }
`;

export const BigLabel = styled.label`
  ${Headline2Css}
`;

export const InputWrapper = styled(Flex)`
  width: inherit !important;
`;

export const FormFlex = styled(Flex)`
  &.header {
    justify-content: space-between;
    align-items: flex-start;
    > h2 {
      margin-bottom: 36px;
    }
    ${respondTo.mobile`
      justify-content: space-around;
      gap: 0;
      margin-bottom: 64px;
    `}
  }
  &.form-body {
    ${respondTo.mobile`
      width: calc(100vw - 48px);
    `}
  }
  > *.form-fade {
    transition: all 233ms ease-in;
    opacity: 0;
    animation: ${fadeInAnimation} 0.5s ease-in-out forwards;
    > * {
      transition: all 233ms ease-in;
      opacity: 0;
      animation: ${fadeInAnimation} 0.5s ease-in-out forwards;
    }
  }
  &.buttons {
    ${respondTo.mobile`
      gap: 20px;
    `}
  }
  ${respondTo.xsdesktop`
    &.reversed {
      flex-direction: column-reverse;
    }
  `}
  ${respondTo.mobile`
    gap: 40px;
    &.reversed {
      flex-direction: column-reverse;
      gap: 70px;
    }
    width: 100%;
    align-items: flex-start !important;
    > button {
      margin-top: 24px;
    }
  `}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  ${respondTo.mobile`
    flex-direction: row-reverse;
  `}
  > * label {
    text-align: right !important;
  }
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 20px;
  color: #DA0D0D;
`;
