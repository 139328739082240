/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

import {
  Button, StyledLink, StyledButton, StyledLinkHash,
} from './styled.components';
import smoothScroll from '../../util/smoothScroll';

export function CustomButton({
  to, className, scrollTo, children, isLink, isHashLink, ...props
}) {
  return (
    isLink ? isHashLink ? (
      <StyledLinkHash to={to} className={className}>
        {children}
      </StyledLinkHash>
    ) : (
      <StyledLink to={to} className={className}>
        {children}
      </StyledLink>
    )
      : (
        <Button {...props} href={to || '#'} className={className} onClick={scrollTo ? (e) => smoothScroll(e, scrollTo) : null} {...props}>
          {children}
        </Button>
      )
  );
}

export function StaticButton({
  className, children, onClick, disabled, ...props
}) {
  return (
    <StyledButton className={className} onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
}
