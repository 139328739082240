/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';

const useScrollY = (callback, height) => {
  useEffect(() => {
    let scrollHeight;
    height ? scrollHeight = height : '900';
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      if (scrollY > scrollHeight) {
        callback(true);
      } else {
        callback(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [callback]);
};

export default useScrollY;
