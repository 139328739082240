import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ColorPalette, ContainerCss } from '../common/styled.components';
import { respondTo } from '../../util/breakpoints';
import { Button, StyledButton, StyledLink } from '../button/styled.components';

export const HamburgerIcon = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  filter: invert(1);
  .scrolled {
    filter: invert(0)
  }
  ${respondTo.mobile`
    display: flex;
  `}
`;

export const TopBar = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  color: white;
  background-color: black !important;
  opacity: 1 !important;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  > strong {
    font-weight: 900;
    margin-left: 5px;
    margin-right: 5px;
  }
  animation: pulse 5000ms 5000ms ease-in-out infinite;
  @keyframes pulse {
    0% {
      color: white;
    }
    33% {
      color: black;
    }
    59% {
      color: white;
    }
  }
  ${respondTo.mobile`
    height: 35px;
    font-size: 14px;
    > i {
      display: none !important;
    }
    > strong {
      margin-left: 4px;
    }
  `}
  ${respondTo.xs`
    font-size: 8px;
    height: 25px;
  `}
`;

export const StyledHeader = styled.header`
  box-sizing: border-box;
  width: 100vw;
  height: 120px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  background-blend-mode: difference;
  font-size: 21px;
  overflow-x: hidden;
  > * a {
    color: ${ColorPalette.white};
    text-shadow: rgba(255, 255, 255, 0.4);
  }
  ${StyledButton}, ${Button} {
    ${respondTo.xsdesktop`
      font-size: 14px !important;
      padding: 16px 24px !important;
    `}
    }
  ${Button} {
    ${respondTo.xsdesktop`
      font-size: 14px !important;
      padding: 16px 32px !important;
    `}
  }
  ${StyledButton} {
    color: white !important;
    border: 1px solid white;
    &:hover {
      color: black !important;
      border-color: black;
    }
  }
  &.scrolled {
    ${StyledButton} {
      color: black !important;
      border: 1px solid black;
    }
    > * ${Button} {
      background-color: black !important;
      color: white !important; 
      &:hover {
        color: black !important; 
        background-color: white !important;
    }
  }
    background-color: white;
    transition: all 300ms ease-in-out;
    ${HamburgerIcon} {
      filter: invert(0);
    }
    > * img {
      filter: grayscale(1) invert(0);
    }
    > * a {
      color: ${ColorPalette.black};
      text-shadow: rgba(255, 255, 255, 0.4);
    }
  }
  ${respondTo.mobile`
    height: 100px;
    align-items: center;
  `}
  ${respondTo.xs`
    height: 80px;
  `}
`;

export const Logo = styled(Link)`
  > img {
    height: 80px;
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
    ${respondTo.xsdesktop`
      height: 70px;
    `}
    ${respondTo.mobile`
      height: 40px !important;
    `}
  }
`;

export const HeaderContainer = styled.div`
  ${ContainerCss}
  height: 90px;
  display: flex;
  z-index: 101;
  justify-content: space-between;
  align-items: center;
  > * {
    &:first-of-type {
      flex-basis: 33.33%;
    }
    flex-basis: 66.67%;
  }
  ${respondTo.mobile`
    height: 70px;
    align-items: center;
  `}
  > .desktop-menu {
    ${respondTo.mobile`
      display: none;
    `}
  }
`;

export const Navigation = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  ${respondTo.xsdesktop`
    &:nth-of-type(2) {
      gap: 12px;
    }
  `}
  > a {
    font-size: 21px;
    display: inline-block;
    text-decoration: none;
    ${respondTo.xsdesktop`
      font-size: 16px;
    `}
  }
  &.flex-end {
    justify-content: flex-end;
  }
  &.flex-start {
    justify-content: flex-start !important;
  }
  ${respondTo.xsdesktop`
    > * #nav-btns > * a {
      font-size: 16px !important;
    }
  `}
  &.static {
    > ${StyledButton}, > ${StyledLink}, > ${Button} {
      background-color: ${ColorPalette.white};
      color: ${ColorPalette.black} !important;
      border-color: black !important;
    &:hover {
      background-color: ${ColorPalette.hoverOverlayWhite};
    }
    &:active {
      background-color: ${ColorPalette.pressedOverlayWhite};
    }
    }
  }
`;

export const NavButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const MobileMenu = styled.div`
  position: fixed;
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  width: 95vw;
  margin: 10px;
  margin-bottom: 0px;
  padding: 24px;
  z-index: 109;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  .close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 200;
  }
`;

export const MobileMenuItem = styled.a`
  border: 0px !important;
  z-index: 109;
  &:visited {
  }
`;

export const MobileOverlay = styled.div`
  ${respondTo.mobile`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 99;
    filter: blur(10 px);
  `}
`;
