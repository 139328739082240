/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalNavigation,
  ModalOverlay,
  ModalWrapper,
} from './styled.components';
import { Container, Flex, Paragraph } from '../common/styled.components';
import { StaticButton } from '../button';

export default function Modal({ modalBody, headline }) {
  const { t } = useTranslation();
  return (
    <ModalOverlay id="modal">
      <ModalWrapper>
        <Container height="100%">
          <ModalNavigation>
            <Flex className="row" align="center" justify="space-between" width="100%">
              <Paragraph className="m" size="m" />
              <StaticButton
                className="black small"
                id="close"
              >
                {t('close')}
              </StaticButton>

            </Flex>
          </ModalNavigation>
          <Flex>
            <Paragraph>
              {headline}
            </Paragraph>
            {modalBody}
          </Flex>
        </Container>
      </ModalWrapper>
    </ModalOverlay>
  );
}
