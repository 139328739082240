/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import CookieConsentBar from './components/cookies';

function App() {
  return (
    <HelmetProvider>
      <Outlet />
      <CookieConsentBar />
    </HelmetProvider>
  );
}

export default App;
