/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.css';

import { StaticButton } from '../button';
import { CookieConsentWrapper } from './styled.components';

export default function CookieConsentBar() {
  const { t } = useTranslation();
  return (
    <CookieConsentWrapper>
      <CookieConsent
        hideOnAccept
        disableButtonStyles
        disableStyles
        containerClasses="cookie-banner-wrapper"
        buttonClasses="black bordered small"
        contentClasses="cookie-content"
        enableDeclineButton
        enable
        declineButtonText={t('cookie-decline')}
        declineButtonClasses="transparent bordered small"
        buttonWrapperClasses="button-wrapper"
        buttonText={t('cookie-agree')}
        expires={150}
        ButtonComponent={(props) => (
          <StaticButton {...props} />)}
      >
        <img className="cookie-image" src="./Cookie.png" alt={t('cookie-icon-alt')} width="66px" height="66px" />
        <div>
          <Trans i18nKey="cookie-message" />
          {' '}
          <Link to="/polityka-prywatnosci">{t('cookie-privacy')}</Link>
        </div>
      </CookieConsent>
    </CookieConsentWrapper>
  );
}
