import React from 'react';
import { Loader, LoaderWrapper } from './styled.component';

export default function CustomLoader() {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
}
