/* eslint-disable max-len */
import React from 'react';

function PrivacyEN() {
  return (
    <div id="pp">
      <span>This Privacy Policy defines</span>
      <b> the rules for storing and accessing data on User Devices </b>
      <span>used by the Service for the purpose of providing electronic services by the Administrator and</span>
      <b> the rules for collecting and processing personal data of Users </b>
      <span>provided by them personally and voluntarily through tools available on the Service.</span>

      <span> This Privacy Policy is an integral part of the </span>
      <a href="https://www.actman.pl/rodo"><span>Service Regulations</span></a>
      <span> which define the rules, rights, and obligations of Users using the Service.</span>
      <h2><b>§1 Definitions</b></h2>
      <ul>
        <li aria-level="1">
          <b>Service</b>
          <span> - the website &quot;actman.pl&quot; operating at the address https://www.actman.pl/</span>
        </li>
        <li aria-level="1">
          <b>External Service</b>
          <br />
          <span> - websites of partners, service providers, or service recipients cooperating with the Administrator.</span>
        </li>
        <li aria-level="1">
          <b>Service Administrator / Data Controller </b>
          <span> - The Service Administrator and Data Controller (hereinafter referred to as the Administrator) is the company &quot;Active Management Sp. z o. o. sp. k.&quot;, conducting business at the address: ul. Urzędnicza 16/2, 30-051 Kraków, with the assigned tax identification number (NIP): 6772377848, and registered under the KRS number: 0000600408, providing electronic services through the Service.</span>
        </li>
        <li aria-level="1">
          <b>User </b>
          <span> - a natural person for whom the Administrator provides electronic services through the Service.</span>
        </li>
        <li aria-level="1">
          <b>Device </b>
          <span> - an electronic device with software through which the User accesses the Service.</span>
        </li>
        <li aria-level="1">
          <b>Cookies </b>
          <span> - text data collected in the form of files placed on the User&lsquo;s Device.</span>
        </li>
        <li aria-level="1">
          <b>GDPR </b>
          <span> - Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation).</span>
        </li>
        <li aria-level="1">
          <b>Personal Data </b>
          <span> - means information about an identified or identifiable natural person &quot;data subject&quot; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name and surname, identification number, location data, an online identifier, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.</span>
        </li>
        <li aria-level="1">
          <b>Processing </b>
          <span> - means an operation or set of operations performed on personal data or sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation, or alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure, or destruction.</span>
        </li>
        <li aria-level="1">
          <b>Restriction of processing </b>
          <span> - means marking stored personal data with the aim of limiting their processing in the future.</span>
        </li>
        <li aria-level="1">
          <b>Profiling </b>
          <span> - means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects concerning that natural person&quot; performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location, or movements.</span>
        </li>
        <li aria-level="1">
          <b>Consent </b>
          <span> - consent of the data subject means any freely given, specific, informed, and unambiguous indication of the data subject&quot; wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</span>
        </li>
        <li aria-level="1">
          <b>Personal data breach </b>
          <span> - means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored, or otherwise processed.</span>
        </li>
        <li aria-level="1">
          <b>Third party </b>
          <span> - means a natural or legal person, public authority, agency or body other than the data subject, controller, processor, and persons who, under the direct authority of the controller or processor, are authorized to process personal data.</span>
        </li>
      </ul>
      <h2><b>§2 Data Protection Officer </b></h2>
      <span>Based on Art. 37 of the GDPR, the Administrator has not appointed a Data Protection Officer.</span>
      <span>For matters related to data processing, including personal data, please contact the Administrator directly.</span>
      <h2><b>§3 Types of Cookies </b></h2>
      <ul>
        <li aria-level="1">
          <b>Internal Cookies </b>
          <span> - files placed and read from the User&quot;s Device by the Service&quot;s IT system.</span>
        </li>
        <li aria-level="1">
          <b>External Cookies </b>
          <span> - files placed and read from the User&quot;s Device by the IT systems of external services. External service scripts that can place cookies on the User&quot;s Devices have been deliberately placed on the Service through scripts and services provided and installed on the Service.</span>
        </li>
        <li aria-level="1">
          <b>Session Cookies </b>
          <span> - files placed and read from the User&quot;s Device by the Service during a single session of that Device. After the session ends, the files are removed from the User&quot;s Device.</span>
        </li>
        <li aria-level="1">
          <b>Persistent Cookies </b>
          <span> - files placed and read from the User&quot;s Device by the Service until they are manually deleted. Files are not automatically deleted after the Device session ends unless the User&quot;s Device configuration is set to delete Cookie files after the Device session ends.</span>
        </li>
      </ul>
      <h2><b>§4 Data storage security </b></h2>
      <ul>
        <li aria-level="1">
          <b>Storage and reading mechanisms of Cookie files </b>
          <span> - The mechanisms for storing, reading, and exchanging data between Cookies saved on the User&quot;s Device and the Service are implemented through built-in web browser mechanisms and do not allow downloading other data from the User&quot;s Device or data from other websites visited by the User, including personal data or confidential information. The transfer of viruses, Trojans, and other worms to the User&quot;s Device is also virtually impossible.</span>
        </li>
        <li aria-level="1">
          <b>Internal Cookie </b>
          <span> - The Cookie files used by the Administrator are safe for the User&quot;s Devices and do not contain scripts, content, or information that could threaten the security of personal data or the security of the Device used by the User.</span>
        </li>
        <li aria-level="1">
          <b>External Cookies </b>
          <span> - The Administrator takes all possible actions to verify and select service partners in the context of user safety. The administrator chooses known, large partners with global social trust. However, he does not have full control over the content of cookies from external partners. The administrator is not responsible for the security of cookies, their content, and the correct use of scripts installed in the service, coming from external services, to the extent permitted by law. The list of partners is provided later in the Privacy Policy.</span>
        </li>
        <li aria-level="1">
          <b>Cookie control</b>
          <ul>
            <li aria-level="2"><span>The User can at any time change the settings for saving, deleting, and accessing data saved by any website on their own.</span></li>
            <li aria-level="2">
              <span>Information on how to disable cookies in the most popular computer browsers is available at: </span>
              <a href="https://nety.pl/jak-wylaczyc-pliki-cookie/"><span>how to disable cookies</span></a>
              <span> or from one of the indicated providers:</span>
              <ul>
                <li aria-level="3">
                  <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=pl">
                    <span>Managing cookies in the </span>
                    <b>Chrome</b>
                    {' '}
                    browser
                  </a>
                </li>
                <li aria-level="3">
                  <a href="https://help.opera.com/pl/latest/web-preferences/">
                    <span>Managing cookies in the </span>
                    <b>Opera</b>
                    {' '}
                    browser
                  </a>
                </li>
                <li aria-level="3">
                  <a href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek">
                    <span>Managing cookies in the </span>
                    <b>FireFox</b>
                    {' '}
                    browser
                  </a>
                </li>
                <li aria-level="3">
                  <a href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies">
                    <span>Managing cookies in the </span>
                    <b>Edge</b>
                    {' '}
                    browser
                  </a>
                </li>
                <li aria-level="3">
                  <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">
                    <span>Managing cookies in the </span>
                    <b>Safari</b>
                    {' '}
                    browser
                  </a>
                </li>
                <li aria-level="3">
                  <a href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11">
                    <span>Managing cookies in the </span>
                    <b>Internet Explorer 11</b>
                    {' '}
                    browser
                  </a>
                </li>
              </ul>
            </li>
            <li aria-level="2"><span>The User can at any time delete all files saved so far using the tools of the Device through which the User uses the Service&quot;s services.</span></li>
          </ul>
        </li>
        <li aria-level="1">
          <b>User-side threats </b>
          <span> - The Administrator uses all possible technical means to ensure the security of the data placed in the cookies. However, it should be noted that ensuring the security of this data depends on both parties, including the activity of the User. The Administrator does not assume responsibility for the interception of this data, impersonating the User&quot;s session, or their deletion, due to the User&quot;s deliberate or unintentional activity, viruses, Trojans, and other spyware that the User&quot;s Device may be or has been infected with. To protect against these threats, users should follow the </span>
          <a href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/"><span>rules for safe use of the internet</span></a>
          <span>.</span>
        </li>
        <li aria-level="1">
          <b>Storage of personal data</b>
          <span> - The Administrator ensures that all efforts are made to ensure that the processed personal data voluntarily entered by Users are secure, access to them is limited, and carried out in accordance with their intended use and processing purposes. The Administrator also ensures that all efforts are made to secure the data they have against their loss, by using appropriate physical and organizational security measures.</span>
        </li>
      </ul>
      <h2><b>§5 Purposes for which Cookie files are used</b></h2>
      <ul>
        <li aria-level="1"><span>Improving and facilitating access to the Service.</span></li>
        <li aria-level="1"><span>Customizing the Service for Users.</span></li>
        <li aria-level="1"><span>Conducting statistics (users, number of visits, types of devices, connections, etc.).</span></li>
      </ul>
      <h2><b>§6 Purposes of processing personal data</b></h2>
      <span>Personal data voluntarily provided by Users are processed for one of the following purposes:</span>
      <ul>
        <li aria-level="1"><span>Provision of electronic services (including contact form).</span></li>
        <li aria-level="1"><span>Communication between the Administrator and Users regarding the Service and data protection issues.</span></li>
        <li aria-level="1"><span>Ensuring the legally justified interest of the Administrator.</span></li>
      </ul>
      <span>Anonymous data collected automatically and automatically processed for one of the following purposes:</span>
      <ul>
        <li aria-level="1"><span>Conducting statistics.</span></li>
        <li aria-level="1"><span>Ensuring the legally justified interest of the Administrator.</span></li>
      </ul>
      <h2><b>§7 Cookies of External Services</b></h2>
      <span>In the Service, the Administrator uses javascript scripts and web components of partners who may place their own cookies on the User&quot;s Device. Remember that in the settings of your browser, you can decide which cookies can be used by individual websites. Below is a list of partners or their services implemented in the Service that may place cookies:</span>
      <ul>
        <li aria-level="1">
          <b>Conducting statistics:</b>
          <ul>
            <li aria-level="2"><a href="https://policies.google.com/privacy?hl=pl"><span>Google Analytics</span></a></li>
          </ul>
        </li>
      </ul>
      <span>Services provided by third parties are beyond the control of the Administrator. These entities may change their terms of service, privacy policies, data processing purposes, and cookie usage methods at any time.</span>
      <h2><b>§8 Types of Collected Data</b></h2>
      <span>The Service collects data about Users. Some data is collected automatically and anonymously, while some data is personal data voluntarily provided by Users when registering for specific services offered by the Service.</span>
      <b> Automatically collected anonymous data: </b>
      <ul>
        <li aria-level="1"><span>IP address.</span></li>
        <li aria-level="1"><span>Browser type.</span></li>
        <li aria-level="1"><span>Screen resolution.</span></li>
        <li aria-level="1"><span>Approximate location.</span></li>
        <li aria-level="1"><span>Pages opened on the website.</span></li>
        <li aria-level="1"><span>Time spent on a specific website page.</span></li>
        <li aria-level="1"><span>Operating system type.</span></li>
        <li aria-level="1"><span>Previous page address.</span></li>
        <li aria-level="1"><span>Referring page address.</span></li>
        <li aria-level="1"><span>Browser language.</span></li>
        <li aria-level="1"><span>Internet connection speed.</span></li>
        <li aria-level="1"><span>Internet service provider.</span></li>
      </ul>
      <b>Data collected when sending a contact form:</b>
      <ul>
        <li aria-level="1"><span>Email address.</span></li>
        <li aria-level="1"><span>Property addresses.</span></li>
        <li aria-level="1"><span>IP address (collected automatically).</span></li>
      </ul>
      <span>Some data (without identifying data) may be stored in cookies. Some data (without identifying data) may be transferred to a statistical services provider.</span>
      <h2><b>§9 Access to personal data by third parties</b></h2>
      <span>As a rule, the only recipient of personal data provided by Users is the Administrator. Data collected as part of the services provided are not transferred or sold to third parties.</span>
      <h2><b>§10 Personal data processing method</b></h2>
      <b>Personal data voluntarily provided by Users:</b>
      <ul>
        <li aria-level="1"><span>Personal data will not be transferred outside the European Union unless it is published as a result of the individual action of the User (e.g., posting a comment or entry), making the data available to anyone visiting the website.</span></li>
        <li aria-level="1"><span>Personal data will not be used for automated decision-making (profiling).</span></li>
        <li aria-level="1"><span>Personal data will not be sold to third parties.</span></li>
      </ul>
      <b> Anonymous data (without personal data) collected automatically: </b>
      <ul>
        <li aria-level="1"><span>Anonymous data (without personal data) will be transferred outside the European Union.</span></li>
        <li aria-level="1"><span>Anonymous data (without personal data) will not be used for automated decision-making (profiling).</span></li>
        <li aria-level="1"><span>Anonymous data (without personal data) will not be sold to third parties.</span></li>
      </ul>
      <h2><b>§11 Legal basis for personal data processing</b></h2>
      <span>The Service collects and processes User data based on:</span>
      <ul>
        <li aria-level="1">
          <span>Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)</span>
          <ul>
            <li aria-level="2">
              <span>Art. 6(1)(a)</span>
              <span />
              <span>The data subject has given consent to the processing of their personal data for one or more specific purposes.</span>
            </li>
            <li aria-level="2">
              <span>Art. 6(1)(b)</span>
              <span />
              <span>Processing is necessary for the performance of a contract to which the data subject is a party or in order to take steps at the request of the data subject prior to entering into a contract.</span>
            </li>
            <li aria-level="2">
              <span>Art. 6(1)(f)</span>
              <span />
              <span>Processing is necessary for the purposes of the legitimate interests pursued by the data controller or by a third party.</span>
            </li>
          </ul>
        </li>
        <li aria-level="1"><span>Act of 10 May 2018 on the protection of personal data (Journal of Laws of 2018, item 1000)</span></li>
        <li aria-level="1"><span>Act of 16 July 2004 - Telecommunications Law (Journal of Laws of 2004, No. 171, item 1800)</span></li>
        <li aria-level="1"><span>Act of 4 February 1994 on copyright and related rights (Journal of Laws of 1994, No. 24, item 83)</span></li>
      </ul>

      <h2><b>§12 Period of Personal Data Processing</b></h2>
      <b> Personal data voluntarily provided by Users: </b>
      <span>As a rule, the specified personal data is stored only for the period of providing the Service within the scope of the Website by the Administrator. They are deleted or anonymized within 30 days from the completion of the service provision.</span>
      <span>An exception is a situation that requires securing legally justified purposes for further processing of this data by the Administrator. In such a situation, the Administrator will retain the specified data, from the moment the User requests their deletion, for no longer than 3 years in the event of a breach or suspicion of a breach of the provisions of the Website&quot;s regulations by the User.</span>

      <b> Anonymous data (without personal data) collected automatically: </b>
      <span>Anonymous statistical data, not constituting personal data, are stored by the Administrator for the purpose of maintaining website statistics indefinitely.</span>

      <h2><b>§13 User Rights Related to Personal Data Processing</b></h2>
      <span>The Website collects and processes User data based on:</span>
      <ul>
        <li aria-level="1">
          <b>Right to access personal data</b>
          <br />
          <span>Users have the right to obtain access to their personal data, carried out upon request to the Administrator.</span>
        </li>
        <li aria-level="1">
          <b>Right to rectify personal data</b>
          <br />
          <span>Users have the right to request the Administrator to promptly rectify inaccurate or incomplete personal data, carried out upon request to the Administrator.</span>
        </li>
        <li aria-level="1">
          <b>Right to erase personal data</b>
          <br />
          <span>Users have the right to request the Administrator to promptly erase personal data, carried out upon request to the Administrator. In the case of user accounts, data deletion involves anonymizing data that enables User identification. The Administrator reserves the right to suspend the execution of the data deletion request to protect the Administrator&quot; legitimate interests (e.g., in case of User violations of the Website&quot;s regulations or data obtained through correspondence).</span>
        </li>
        <li aria-level="1">
          <b>Right to restrict the processing of personal data</b>
          <br />
          <span>Users have the right to restrict the processing of personal data in cases specified in art. 18 of the GDPR, including questioning the accuracy of personal data, carried out upon request to the Administrator.</span>
        </li>
        <li aria-level="1">
          <b>Right to data portability</b>
          <br />
          <span>Users have the right to receive from the Administrator personal data concerning the User in a structured, commonly used, and machine-readable format, carried out upon request to the Administrator.</span>
        </li>
        <li aria-level="1">
          <b>Right to object to the processing of personal data</b>
          <br />
          <span>Users have the right to object to the processing of their personal data in cases specified in art. 21 of the GDPR, carried out upon request to the Administrator.</span>
        </li>
        <li aria-level="1">
          <b>Right to lodge a complaint</b>
          <br />
          <span>Users have the right to lodge a complaint with the supervisory authority responsible for data protection.</span>
        </li>
      </ul>

      <h2><b>§14 Contacting the Administrator</b></h2>
      <span>You can contact the Administrator in one of the following ways:</span>
      <ul>
        <li aria-level="1">
          <b>Mailing address </b>
          <span> - Active Management Sp. z o. o. sp. k., ul. Urzędnicza 16/2, 30-051 Kraków</span>
        </li>
        <li aria-level="1">
          <b>Email address </b>
          <span> - info@actman.pl</span>
        </li>
        <li aria-level="1">
          <b>Phone contact </b>
          <span> - +48 12 290 12 50</span>
        </li>
        <li aria-level="1">
          <b>Contact form </b>
          <span> - available at: www.actman.pl</span>
        </li>
      </ul>

      <h2><b>§15 Website Requirements</b></h2>
      <ul>
        <li aria-level="1"><span>Limiting the storage and access to Cookie files on the User&lsqo;s Device may cause certain Website functions to malfunction.</span></li>
        <li aria-level="1"><span>The Administrator is not responsible for any malfunctions of Website functions in case the User limits in any way the possibility of storing and reading Cookie files.</span></li>
      </ul>

      <h2><b>§16 External Links</b></h2>
      <span>In the Website - articles, posts, entries, or User comments may contain links to external websites not affiliated with the Website owner. These links and the pages or files indicated under them may be dangerous for your Device or pose a threat to the security of your data. The Administrator is not responsible for the content located outside the Website.</span>

      <h2><b>§17 Changes in Privacy Policy</b></h2>
      <ul>
        <li aria-level="1"><span>The Administrator reserves the right to make any changes to this Privacy Policy without the need to inform Users in the scope of the use and use of anonymous data or the use of Cookie files.</span></li>
        <li aria-level="1"><span>Changes to the Privacy Policy will be published on this subpage of the Website.</span></li>
        <li aria-level="1"><span>The changes will take effect upon publication.</span></li>
      </ul>
    </div>
  );
}

export default PrivacyEN;
