import { useEffect, useState } from 'react';

export default function useDetectMobile(mobileSize) {
  let width = 1025;
  width += mobileSize;
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth < width,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(typeof window !== 'undefined' && window.innerWidth < width);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return isMobile;
}
