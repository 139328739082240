/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterContainer, FooterFlex, StyledFooter } from './styled.components';
import {
  Copyright, Flex, FooterHeadline, Paragraph,
} from '../common/styled.components';
import { CustomButton } from '../button';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <StyledFooter>
      <FooterContainer>
        <Flex>
          <img className="logo" src="./logo-actman-vertical.png" alt="logo act-man" />
        </Flex>
        <FooterFlex className="wrapper">
          <FooterFlex direction="column">
            <FooterHeadline>
              {t('registered-address')}:
            </FooterHeadline>
            <Paragraph>
              <a href="https://goo.gl/maps/NBAhZTXEMzU2f5iP7" target="_blank" rel="noreferrer">
                ul. Urzędnicza 16/2,<br />
                30-051 Kraków
              </a>
            </Paragraph>
          </FooterFlex>
          <FooterFlex direction="column">
            <FooterHeadline>
              {t('cracow-office')}:
            </FooterHeadline>
            <Paragraph>
              <a href="https://goo.gl/maps/uuzh3coBKYyXjVrr6" target="_blank" rel="noreferrer">
                ul. Lubicz 17E/1,<br />
                30-503 Kraków<br />
              </a>
              <a className="phoneNumber" href="tel: +48 12 290 12 50">
                Tel: +48 12 290 12 50
              </a>
            </Paragraph>
          </FooterFlex>
          <FooterFlex direction="column">
            <FooterHeadline>
              {t('warsaw-office')}:
            </FooterHeadline>
            <Paragraph>
              <a href="https://goo.gl/maps/tXh8qB9DKS1ekEdj9" target="_blank" rel="noreferrer">
                ul. Brodzińskiego 27,<br />
                01-557 Warszawa<br />
              </a>
              <a className="phoneNumber" href="tel: +48 667 380 002">
                Tel: +48 667 380 002
              </a>
            </Paragraph>
          </FooterFlex>
          <FooterFlex direction="column">
            <FooterHeadline>
              Active Management sp. z o.o. sp. k.
            </FooterHeadline>
            <Paragraph>
              KRS 0000600408<br />
              NIP 6772377848<br />
              REGON 122972591
            </Paragraph>
          </FooterFlex>
        </FooterFlex>
        <Flex justify="space-between">
          <Flex gap="20px">
            <CustomButton className="small" to="mailto: info@actman.pl">info@actman.pl</CustomButton>
            <CustomButton className="small" to="tel: +48 667 350 044">
              +48 667 350 044 {' '}
              {t('emergency')}
            </CustomButton>
            <CustomButton isLink className="transparent small" to="/polityka-prywatnosci">{t('privacy-policy')}</CustomButton>
          </Flex>
          <Copyright>
            © Copyright 2023 Active Management
          </Copyright>
        </Flex>
      </FooterContainer>
    </StyledFooter>
  );
}
