const smoothScroll = (e, targetId) => {
  e.preventDefault();

  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop - 180,
      behavior: 'smooth',
    });
  }
};

export default smoothScroll;
