/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollRestoration } from 'react-router-dom';
import Layout from '../../../components/layout';
import {
  Container,
  Headline1,
  Flex,
  Paragraph,
} from '../../../components/common/styled.components';
import { CustomButton } from '../../../components/button';
import { StyledMain } from '../ServicePageTemplate/styled.components';
import PrivacyEN from './privacyEN';
import PrivacyPL from './privacyPL';

export default function PrivacyPolicy() {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  return (
    <Layout scrolledNav>
      <ScrollRestoration />
      <StyledMain className="page-template" style={{ paddingTop: '160px' }}>
        <Container direction="column" className="page-template " width="100%" height="auto">
          <CustomButton isLink className="transparent small" to="/">{t('go-back')}</CustomButton>
          <Flex direction="column">
            <Headline1 className="h2css">
              {t('privacy-policy')}
            </Headline1>
            <Paragraph>
              {currentLanguage === 'pl' ? <PrivacyPL /> : <PrivacyEN />}
            </Paragraph>
          </Flex>
        </Container>
      </StyledMain>
    </Layout>
  );
}
