/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollRestoration } from 'react-router-dom';
import Layout from '../../../components/layout';
import {
  Section,
  Container,
  Headline1,
  Flex,
  Subtitle,
  Headline2,
} from '../../../components/common/styled.components';
import {
  MainImageContainer, ServiceContainer, StyledMain, StyledParagraph,
} from './styled.components';
import { CustomButton } from '../../../components/button';
import { DropdownGroup } from '../components/dropdown';
import CustomLoader from '../../../components/loader';

export default function ServicePageTemplate({ pageContext }) {
  const { t } = useTranslation();
  return (
    <Layout scrolledNav>
      {!pageContext.title ? (
        <CustomLoader />
      ) : null}
      <ScrollRestoration />
      <StyledMain>
        <Section className="page-template">
          <Container direction="column" className="page-template " width="100%" height="auto">
            <CustomButton className="transparent small" to="/#services" isLink isHashLink>{t('go-back')}</CustomButton>
            <Flex direction="column">
              <Headline1 className="h2css">
                {pageContext.title}
              </Headline1>
              <MainImageContainer>
                <img src={pageContext.image.url} alt="placeholder" />
              </MainImageContainer>
              <Headline2>
                {pageContext.subtitle}
              </Headline2>
              <StyledParagraph className="content">
                {pageContext.paragraf}
              </StyledParagraph>
            </Flex>
          </Container>
        </Section>
        <Section>
          <ServiceContainer>
            <Flex direction="row" justify="space-between">
              <Subtitle className="servicePage">
                {t('services')}
              </Subtitle>
              <Flex width="1024px">
                <DropdownGroup
                  dropdownContent={pageContext.content}
                />
              </Flex>
            </Flex>
          </ServiceContainer>
        </Section>
        {/* <PersonSection bgimg={Person}>
          <Container>
            <PersonFlex direction="column" gap="42px" width="74%">
              <Paragraph>Dlaczego Active Management?</Paragraph>
              <Headline2>
                “Jestem specjalistą ds. zarządzania nieruchomościami, skoncentrowanym na zarządzaniu i administrowaniu”
              </Headline2>
              <Paragraph style={{ paddingBottom: '20px' }} italic>Dominika Dulczewska, CEO Active Management</Paragraph>
              <CustomButton className="black small bordered" to="/#contact">Porozmawiajmy </CustomButton>
            </PersonFlex>
          </Container>
        </PersonSection> */}
      </StyledMain>
    </Layout>
  );
}
