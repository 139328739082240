/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { css } from 'styled-components';

export const breakpoints = {
  xs: '300px',
  s: '568px',
  mobile: '1024px',
  beforemobile: '1366px',
  xsdesktop: '1600px',
  xl: '1919px',
};

export const respondTo = Object.entries(breakpoints).reduce(
  (breakpoints, [key, value]) => {
    breakpoints[key] = (...args) => css`
      @media (max-width: ${value}) {
        ${css(...args)};
      }
    `;
    return breakpoints;
  },
  {},
);
