import styled from 'styled-components';
import { respondTo } from '../../../util/breakpoints';
import {
  Container, Flex, Paragraph, Section,
} from '../../../components/common/styled.components';

export const StyledMain = styled.main`
  display: block;
  ${respondTo.mobile`
    padding-top: 100px;
  `}
`;

export const MainImageContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: 400px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.2);
  &.loading {
    @keyframes pulse {
      0% {
        opacity: 0.2;
      } 50% {
        opacity: 0.1;
      } 100% {
        opacity: 0.2;
      }
    }
  }
  > img {
    max-height: 620px;
    width: 100%;
    object-fit: cover;
  }
  ${respondTo.mobile`
    min-height: 10px;
  `}
`;
export const ServiceContainer = styled(Container)`
  padding-top: 0px !important;
  padding-bottom: 120px;
  .FAQ {
    width: 60%;
    ${respondTo.mobile`
      width: 100%;
    `}
  }
  ${respondTo.mobile`
    &:first-of-type {
      padding-top: 0;
    }
  `}
    > * h3 {
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    ${respondTo.mobile`
      text-align: left !important;
      justify-self: flex-start !important;
      width: 100%;
      font-size: 24px;
    `}
  }
`;

export const PersonSection = styled(Section)`
  background-position: right bottom;
  background-size: 1024px;
  padding-top: 101px;
  padding-bottom: 100px;
  background-color: black;
  color: white;
  ${respondTo.xsdesktop`
    background-size: 800px;
  `}
  ${respondTo.mobile`
    background-image: none;
    padding: 60px 0;
  `}
  > * h2 {
    font-size: 60px;
    ${respondTo.mobile`
      font-size: 28px;
    `}
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin: 0;
  text-align: left;
  width: 100%;
  justify-self: flex-start;
`;

export const PersonFlex = styled(Flex)`
  ${respondTo.mobile`
    gap: 0px;
  `}
`;
