export default function parseHTMLContent(content) {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(content, 'text/html');

  const h3Elements = htmlDoc.querySelectorAll('h3');
  const sectionsData = Array.from(h3Elements).map((h3, index) => {
    let nextElement = h3.nextElementSibling;
    const contentElements = [];
    while (nextElement && nextElement.tagName.toLowerCase() !== 'h3') {
      contentElements.push(nextElement);
      nextElement = nextElement.nextElementSibling;
    }

    const parsedElements = contentElements.map((element) => element.outerHTML).join('');

    return {
      id: index,
      title: h3.textContent,
      text: parsedElements,
    };
  });
  return sectionsData;
}
