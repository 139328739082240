import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from './locales/en.json'; // Path to your English translation file
import PL from './locales/pl.json'; // Path to your French translation file

const resources = {
  en: {
    translation: EN,
  },
  pl: {
    translation: PL,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pl', // Set the default language here
  fallbackLng: 'pl', // Set the fallback language here
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
