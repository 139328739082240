/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Logo,
  Navigation,
  StyledHeader,
  HeaderContainer,
  HamburgerIcon,
  MobileMenu,
  MobileMenuItem,
  TopBar,
  MobileOverlay,
} from './styled.components';
import useScrollY from '../hooks/useScrollY';
import { Flex } from '../common/styled.components';
import { CustomButton } from '../button';
import { StyledButton } from '../button/styled.components';

// eslint-disable-next-line react/prop-types
export function Header({ scrolledNav }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [isMainPage, setIsMainPage] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [notCurrentLanguage, setNotCurrentLanguage] = useState(false);

  const currentLanguage = i18n.language;

  const handleClick = () => {
    if (typeof window !== 'undefined' && location.pathname === '/') {
      window.scrollTo(0, 0);
    }
  };

  const toggleLanguage = () => {
    const nextLanguage = currentLanguage === 'en' ? 'pl' : 'en';
    i18n.changeLanguage(nextLanguage);
  };

  useEffect(() => {
    const doIt = () => {
      if (currentLanguage === 'pl') {
        setNotCurrentLanguage('en');
      } else {
        setNotCurrentLanguage('pl');
      }
    };
    doIt();
  }, [currentLanguage, isMainPage]);

  useEffect(() => {
    if (location.pathname === '/') setIsMainPage(true);
    else {
      document.getElementById('header').classList.add('scrolled');
      document.getElementById('nav-btns').classList.remove('static');
      setIsMainPage(false);
    }
  }, [location]);

  const toggleScrolled = () => {
    if (scrolledNav) {
      document.getElementById('header').classList.add('scrolled');
      document.getElementById('nav-btns').classList.remove('static');
    } else {
      document.getElementById('header').classList.add('scrolled');
      document.getElementById('nav-btns').classList.remove('static');
      if (document.documentElement.scrollTop === 0) {
        document.getElementById('header').classList.remove('scrolled');
        document.getElementById('nav-btns').classList.add('static');
      }
    }
  };
  useScrollY(toggleScrolled);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <StyledHeader id="header" className={scrolledNav ? 'scrolled' : null}>
        <TopBar className="phoneNumber" onClick={() => window.location.href = 'tel: +48667350044'}>
          <Trans i18nKey="alarm-phone" values={{ phone: '+48 667 350 044' }} />
        </TopBar>
        <HeaderContainer>
          <Logo to={location.pathname === '/' ? null : '/'} onClick={() => handleClick()}>
            <img src="./logoactman.png" alt={t('site-logo')} />
          </Logo>
          <HamburgerIcon onClick={toggleMobileMenu}>
            <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="1" fill="black" />
              <rect y="7" width="25" height="1" fill="black" />
              <rect y="14" width="25" height="1" fill="black" />
            </svg>
          </HamburgerIcon>
          <Flex className="desktop-menu" align="center">
            <Navigation className="links">
              <a href="/#about">{t('about')}</a>
              <a href="/#services">{t('services')}</a>
              <a href="/#gallery">{t('gallery')}</a>
              <a href="/#reviews">{t('references')}</a>
              <a href="/#contact">
                {t('contact')}
              </a>
            </Navigation>
            <Navigation id="nav-btns" className="flex-end static">
              <CustomButton
                to="https://mmsoft.com.pl/lokale.php?Adm=37&Nag=2"
                traget="_blank"
                className="white small"
              >
                {t('my-apartment-login')}
              </CustomButton>
              <StyledButton onClick={() => toggleLanguage()} className="transparent small">
                {notCurrentLanguage && notCurrentLanguage.toUpperCase()}
              </StyledButton>
            </Navigation>
          </Flex>
        </HeaderContainer>
      </StyledHeader>
      {isMobileMenuOpen ? (
        <MobileMenu>
          <MobileMenuItem onClick={() => setIsMobileMenuOpen(false)} href="/#about">{t('about')}</MobileMenuItem>
          <MobileMenuItem onClick={() => setIsMobileMenuOpen(false)} href="/#services">{t('services')}</MobileMenuItem>
          <MobileMenuItem onClick={() => setIsMobileMenuOpen(false)} href="/#gallery">{t('gallery')}</MobileMenuItem>
          <MobileMenuItem onClick={() => setIsMobileMenuOpen(false)} href="/#contact">{t('contact')}</MobileMenuItem>
          <MobileMenuItem onClick={() => setIsMobileMenuOpen(false)} href="https://mmsoft.com.pl/lokale.php?Adm=37&Nag=2">{t('login-my-apartment')}</MobileMenuItem>
          <div className="close-btn" onClick={() => setIsMobileMenuOpen(false)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.17313 1L18.8269 19M1 18.4904L19 1.50962" stroke="black" />
            </svg>
          </div>
          <MobileMenuItem onClick={() => toggleLanguage()} className="transparent small">
            {notCurrentLanguage && notCurrentLanguage.toUpperCase()}
          </MobileMenuItem>
        </MobileMenu>
      ) : null}
      {isMobileMenuOpen && <MobileOverlay onClick={() => setIsMobileMenuOpen(false)} />}
    </>
  );
}

export default Header;
