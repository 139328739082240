async function fetchPageContext(setStateFunction, currentLanguage, page) {
  try {
    setStateFunction({
      title: '',
      subtitle: '',
      content: '',
      image: {
        url: '',
      },
    });
    let response;
    if (currentLanguage === 'pl') {
      response = await fetch(
        `https://cms.actman.pl/wp-json/wp/v2/pages/${page.pl}?acf_format=standard`,
      );
    }
    if (currentLanguage === 'en') {
      response = await fetch(
        `https://cms.actman.pl/wp-json/wp/v2/pages/${page.en}?acf_format=standard`,
      );
    }
    const data = await response.json();
    setStateFunction(data.acf);
  } catch (e) {
    console.error(e);
  }
}

export default fetchPageContext;
