/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Paragraph,
  Subtitle,
} from '../../../../components/common/styled.components';
import { DropdownGroupWrapper, DropdownWrapper, ToggleIcon } from './styled.component';
import { CustomButton } from '../../../../components/button';

export function Dropdown({ context }) {
  const [isParagraphVisible, setIsParagraphVisible] = useState(false);
  const { t } = useTranslation();

  const toggleParagraphVisibility = () => {
    setIsParagraphVisible(!isParagraphVisible);
  };
  return (
    <DropdownWrapper
      direction="row"
      width="100%"
      className="row"
      align="center"
      justify="space-between"
      onClick={() => toggleParagraphVisibility()}
    >
      <article style={{ width: '100%' }}>
        <Flex justify="space-between" heightProp="100%" align="flex-end">
          <Subtitle>{context.title}</Subtitle>
          <ToggleIcon className={isParagraphVisible ? null : 'toggled'}>{!isParagraphVisible ? '+' : '-'}</ToggleIcon>
        </Flex>
        <Paragraph
          size="m"
          style={{
            maxHeight: isParagraphVisible ? '1100px' : '0',
            opacity: isParagraphVisible ? '1' : '0',
            transition: 'max-height 0.3s ease, opacity 0.3s ease',
            overflow: 'hidden',
          }}
        >
          <p dangerouslySetInnerHTML={{ __html: context.text }} />
          <CustomButton isHashLink isLink to="/#contact" className="small transparent faq">
            {`${t('lets-talk')} →`}
          </CustomButton>
        </Paragraph>
      </article>
    </DropdownWrapper>
  );
}

export function DropdownGroup({ dropdownContent }) {
  return (
    <DropdownGroupWrapper direction="column">
      {dropdownContent.map((item) => (
        <Dropdown key={item.title} context={item} />
      ))}
    </DropdownGroupWrapper>
  );
}
