/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable react/void-dom-elements-no-children */
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  Container,
  Headline2,
  Paragraph,
} from '../common/styled.components';
import {
  CheckboxWrapper,
  FormFlex,
  FormSection,
  StyledLabel,
} from './styled.components';
import InputComponent from './FormInput';
import { CustomButton, StaticButton } from '../button';

export default function ContactForm() {
  const form = useRef();
  const { t } = useTranslation();

  const [formSuccess, setFormSuccess] = useState('');
  const [formError, setError] = useState('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('E-mail jest wymagany'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      address: '',
      textarea: '',
      agreement: '',
    },
    validationSchema,
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = t('error-empty-email');
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = t('error-email');
      }

      if (!values.textarea) {
        errors.textarea = t('error-textarea');
      }

      if (!values.agreement) {
        errors.agreement = t('error-agreement');
      }
      return errors;
    },
    onSubmit: async () => {
      try {
        const result = await emailjs.sendForm('service_w4i922b', 'template_mk5o51e', form.current, 'kCqTJmm7V-vVONLuE');
        if (result) {
          setFormSuccess(t('email-ok'));
          formik.resetForm({ values: '' });
        }
      } catch (error) {
        setError(t('email-error'));
      }
    },
  });
  return (
    <FormSection
      id="contact"
      className="contact"
    >
      <Container direction="column">
        <FormFlex className="header">
          <Headline2 className="extra-large">{t('contact')}</Headline2>
          <FormFlex gap="12px" justify="flex-end" width="auto">
            <CustomButton
              className="copy small black bordered"
              to="mailto: info@actman.pl"
            >
              info@actman.pl
            </CustomButton>
            <CustomButton
              className="copy small black bordered phoneNumber"
              to="tel: +48 667 350 044"
            >
              +48 667 350 044
              {' '}
              {`(${t('emergency').toUpperCase()})`}
            </CustomButton>
          </FormFlex>
        </FormFlex>
        <FormFlex className="reversed" justify="space-between" gap="120px">
          <FormFlex direction="column" width="550px" gap="32px">
            <FormFlex className="buttons" direction="column" gap="20px">
              <Paragraph>
                {t('warsaw-office')}
              </Paragraph>
              <CustomButton
                className="copy small black bordered"
                to="https://goo.gl/maps/tXh8qB9DKS1ekEdj9"
                target="_blank"
              >
                ul. Brodzińskiego 27, 01-557 Warszawa
              </CustomButton>
              <CustomButton
                className="copy small black bordered phoneNumber"
                to="tel: +48 667 380 002"
              >
                +48 667 380 002
              </CustomButton>
              <Paragraph>
                {t('cracow-office')}
              </Paragraph>
              <CustomButton
                className="copy small black bordered"
                to="https://goo.gl/maps/uuzh3coBKYyXjVrr6"
                target="_blank"
              >
                ul. Lubicz 17E/1, 31-503 Kraków
              </CustomButton>
              <CustomButton
                className="copy small black bordered phoneNumber"
                to="tel: +48 12 290 12 50"
              >
                +48 12 290 12 50
              </CustomButton>
            </FormFlex>
          </FormFlex>
          <form onSubmit={formik.handleSubmit} ref={form}>
            <FormFlex className="form-body" direction="column" width="950px" gap="50px" align="flex-end">
              <FormFlex direction="column" gap="40px" alignSelf="flex-end">
                <FormFlex direction="row" gap="20px">
                  <InputComponent className={formik.errors.email && 'error'} errorCode={formik.errors.email} type="email" formik={formik} onClick={() => document.getElementById('email').classList.add('full')} placeholder="Wpisz e-mail..." name="email" label={t('my-mail')} id="email" onChange={formik.handleChange} value={formik.values.email} />
                  <InputComponent className={formik.errors.address && 'error'} errorCode={formik.errors.address} type="text" formik={formik} onClick={() => document.getElementById('address').classList.add('full')} placeholder="Wprowadź adres..." name="address" id="address" label={t('address')} onChange={formik.handleChange} value={formik.values.address} />
                </FormFlex>
                <InputComponent className={formik.errors.textarea && 'error'} formik={formik} errorCode={formik.errors.textarea} onClick={() => document.getElementById('textarea').classList.add('full')} placeholder="Wpisz treść..." label={`${t('your-message')}*`} isTextarea id="textarea" onChange={formik.handleChange} name="textarea" value={formik.values.textarea} />
                <Paragraph className="small" size="s">
                  {t('privacy-policy-rules')}
                  <Link className="link" to="/polityka-prywatnosci#" style={{ textDecoration: 'underline' }}>{t('lowercase-privacy-policy')}</Link>
                </Paragraph>
                <FormFlex justify="space-between">
                  <CheckboxWrapper label={t('privacy-policy')} gap="10px">
                    <InputComponent className={formik.errors.agreement && 'error'} style={{ justifySelf: 'flex-start' }} type="checkbox" onChange={formik.handleChange} value={formik.values.agreement} id="agreement" name="agreement" />
                    <StyledLabel className="lowercase">
                      <Link className="link" to="/polityka-prywatnosci">
                        <Trans i18nKey="privacy-policy-acceptance" />
                        *
                      </Link>
                    </StyledLabel>
                  </CheckboxWrapper>
                  <StaticButton id="form_submit" type="submit" className="white">{t('send')}</StaticButton>
                </FormFlex>
                <div>
                  <span style={{ color: 'red' }}>
                    {formik.errors.agreement}
                    {formError}
                  </span>
                  <span style={{ color: 'green' }}>
                    {formSuccess}
                  </span>
                </div>
              </FormFlex>
            </FormFlex>
          </form>
        </FormFlex>
      </Container>
    </FormSection>
  );
}
