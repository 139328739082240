import styled from 'styled-components';
import { ContainerCss, Flex, Spacing } from '../common/styled.components';
import FooterBg from '../../img/footerBg.png';
import { respondTo } from '../../util/breakpoints';
import { Navigation } from '../navigation/styled.components';

export const StyledFooter = styled.footer`
  width: 100vw;
  height: 704px;
  background-image: url(${FooterBg});
  background-position: center center;
  background-size: cover;
  > * img {
    max-width: 300px;
    ${respondTo.mobile`
      align-self: center;
      margin-bottom: 24px;
      max-width: 250px;
    `}
    filter: grayscale(1);
  }
  > * a, > * span {
    font-family: Satoshi;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }
  ${respondTo.mobile`
    height: auto;
    padding: 40px 0;
    border-sizing: border-box;
    > * ${Navigation}:first-child {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
    }
  `}
  > * .copy {
    margin-top: 60px;
    text-align: center;
    align-self: center;
  }
`;

export const FooterContainer = styled.div`
  > * h4, > * div {
    font-family: 'Satoshi';
    font-weight: 400;
    font-size: 24px;
    ${respondTo.mobile`
      font-size: 18px;
    `}
  } 
  ${ContainerCss}
  padding-top: ${Spacing.pt64};
  padding-bottom: ${Spacing.pt80};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FooterHeader = styled.span`
  font-size: 240px !important;
  color: #E5C299 !important;
  mix-blend-mode: difference;
  ${respondTo.mobile`
    font-size: 120px !important;
  `}
`;

export const ContactDetails = styled.span`
  cursor: pointer;
  transition: all 233ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;

export const FooterFlex = styled(Flex)`
  > ${FooterHeader} {
    margin-bottom: 0;
  }
  > * p {
    > span {
      margin-bottom: 10px; 
    }
    margin-top: 12px;
  }
  ${respondTo.mobile`
    gap: 0px;
    text-align:left;
    align-items: center;
    > * p { 
      font-size: 24px;
    }
  `}
  &.wrapper {
    margin: 32px 0;
    ${respondTo.mobile`
      gap: 0px;
    `}}
`;
