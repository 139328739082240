/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'core-js/stable';
import { I18nextProvider } from 'react-i18next';
import {
  createBrowserRouter, RouterProvider,
} from 'react-router-dom';
import './index.css';
import MainPage from './pages/main';
import PropertyRentManagement from './pages/services/wspolnoty';
import RentalAndManagement from './pages/services/rentalManagement';
import PRS from './pages/services/prs';
import CommercialPropertyManagement from './pages/services/commercialProperties';
import PrivacyPolicy from './pages/templates/PrivacyPolicy';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      {
        path: '/zarzadzanie-wspolnotami',
        element: <PropertyRentManagement />,
      },
      {
        path: '/zarzadzanie-komercyjne',
        element: <CommercialPropertyManagement />,
      },
      {
        path: '/zarzadzanie-najmem',
        element: <RentalAndManagement />,
      },
      {
        path: '/najem-instytucjonalny',
        element: <PRS />,
      },
      {
        path: '/polityka-prywatnosci',
        element: <PrivacyPolicy />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>,
);

reportWebVitals();
