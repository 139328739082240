import { styled } from 'styled-components';
import { respondTo } from '../../util/breakpoints';

export const ModalWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 100;
  padding: 20px 0;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  z-index:999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  ${respondTo.mobile`
    display: none;
  `}
`;

export const ModalNavigation = styled.nav`
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  padding: 24px 60px;
  top: 0;
  left: 0;
  z-index: 999;
  ${respondTo.mobile`
    padding: 12px 24px;
  `}
`;
