import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ServicePageTemplate from '../templates/ServicePageTemplate';
import richTextParse from '../../api/richTextParse';
import fetchPageContext from '../../api/fetchPageContext';

function PropertyRentManagement() {
  const [pageContext, setPageContext] = useState({
    title: '',
    subtitle: '',
    content: '',
    image: {
      url: '',
    },
  });

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    fetchPageContext(setPageContext, currentLanguage, { pl: 10, en: 175 });
  }, [currentLanguage]);

  const parsedSections = richTextParse(pageContext.content);

  return (
    <ServicePageTemplate
      pageContext={{ ...pageContext, content: parsedSections }}
    />
  );
}

export default PropertyRentManagement;
