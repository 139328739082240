import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import {
  ColorPalette,
  Flex,
  Section,
  Spacing,
} from '../../components/common/styled.components';

export const ScrollDownAction = styled.div`
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: white;
  color: black;
  z-index: 99;
`;

// eslint-disable-next-line import/prefer-default-export
export const HeaderSubText = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 145%;
  
  ${respondTo.mobile`
    font-size: 24px;
    text-align: center;
    margin-bottom: 120px;
  `}
`;

export const MainSection = styled(Section)`
  background-size: 100% 55%;
  background-color: ${ColorPalette.black};
  padding-top: ${Spacing.pt220};
  padding-bottom: 120px;
  transition: all 133ms ease-out;
  position: relative;

  ${respondTo.mobile`
    padding-top: 160px;
    background-size: cover;
    background-position: center center;
  `}

  &::before {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: black;
  }
  &.scrolled {
    padding-top: ${Spacing.pt160};
    padding-bottom: 0;
    background-size: 0%;
    transition: all 300ms 22ms ease-out;
  }
`;

export const IntroductionSection = styled(Section)`
  background-color: ${ColorPalette.black};
  color: ${ColorPalette.white};
  padding-bottom: 120px;
  ${respondTo.mobile`
    padding-top: 60px;
  `}
`;

export const AboutFlex = styled(Flex)`
  ${respondTo.mobile`
    gap: 0px;
  `}
`;

export const ReviewSection = styled(Section)`
  margin-top: 160px;
  background-color: ${ColorPalette.black};
  background-size: cover;
  padding-bottom: 120px;
  ${respondTo.mobile`
    margin-top: 40px;
    padding-bottom: 40px;
    gap: 0;
    height: 700px;
    > * h3 {
      margin: 0;
    }
    max-height:770px;
  `}
  ${respondTo.xs`
    max-height:1188px;
    height: 858px;
  `}
`;

export const InNumbersWrapper = styled(Flex)`
  gap: 40px;
  ${respondTo.mobile`
    gap: 20px;
  `}
  transition-delay: 200ms;
  &:nth-of-type(2) {
    transition-delay: 400ms;
  }
  &:nth-of-type(3) {
    transition-delay: 600ms;
  }
  > * h1 {
    text-align: left;
    margin-bottom: 0px;
    ${respondTo.mobile`
      font-size: 48px !important;
      line-height: 110%;
    `}
  }
`;
