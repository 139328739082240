/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Header } from '../navigation';
import Footer from '../footer';

export default function Layout({
  children, scrolledNav, metaTitle, metaDescription,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    Hotjar.init('3649325', 6);
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <Helmet>
        <title>{metaTitle ? t(String(metaTitle)) : t('meta-title')}</title>
        <meta
          name="description"
          content={
          t(String(metaDescription))
        }
        />
      </Helmet>
      <Header scrolledNav={scrolledNav} />
      {children}
      <Footer />
    </div>
  );
}
