/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessageContainer, InputWrapper, StyledInput, StyledLabel, StyledTextarea,
} from './styled.components';

export default function InputComponent({
  label, id, isBig, isTextarea, inputClassName, errorCode, ...props
}) {
  const { t } = useTranslation();
  return (
    <InputWrapper direction={!isBig ? 'column' : 'row'} gap={isBig && '20px'} align={isBig && 'flex-end'}>
      <StyledLabel for={id}>{label}</StyledLabel>
      {!isTextarea ? (
        <StyledInput className={inputClassName} id={id} {...props} />
      ) : (
        <StyledTextarea id={id} {...props} placeholder={t('Wpisz treść')} />
      )}
      <p>
        {' '}
      </p>
      <ErrorMessageContainer>{errorCode}</ErrorMessageContainer>
    </InputWrapper>
  );
}
