import { Link } from 'react-router-dom';
import { styled, css } from 'styled-components';

import { HashLink } from 'react-router-hash-link';
import { respondTo } from '../../util/breakpoints';
import { ColorPalette } from '../common/styled.components';

const ButtonCss = css`
  background-color: transparent;
  border: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 24.7097px 45.4194px;
  border-radius: 143.041px;
  height: 76px;
  font-weight: 400;
  font-size: 28px;
  z-index: 5;
  cursor: pointer;
  text-align: center;
  &.alarm {
    border: 3px solid white !important;
  }
  &.black, &:link.black, &:visited {
    background-color: ${ColorPalette.black};
    color: ${ColorPalette.white};
    &.bordered {
      border: 1px solid ${ColorPalette.white};
      color: ${ColorPalette.white};
      &:hover {
        background-color: ${ColorPalette.hoverOverlayBlack};
      }
    }
    &.service-item {
      &:hover {
        background-color: ${ColorPalette.white};
        color: ${ColorPalette.black};
      }
    }
    &:hover {
      background-color: ${ColorPalette.hoverOverlayBlack};
    }
    &:active {
      background-color: ${ColorPalette.pressedOverlayBlack};
    }
  }

  &.transparent, &:link, &:visited {
    background-color: transparent;
    border: 1px solid ${ColorPalette.black};
    color: ${ColorPalette.black};
    &.light {
      color: ${ColorPalette.white};
      border: 1px solid ${ColorPalette.white};
    }
    &:hover {
      background-color: ${ColorPalette.hoverOverlayWhite};
    }
    &:active {
      background-color: ${ColorPalette.pressedOverlayWhite};
    }
  }

  &.white, &.white:link, &.white:visited {
    background-color: ${ColorPalette.white};
    color: ${ColorPalette.black};
    &:hover {
      background-color: ${ColorPalette.hoverOverlayWhite};
    }
    &:active {
      background-color: ${ColorPalette.pressedOverlayWhite};
    }
    .scrolled {
      background-color: ${ColorPalette.black};
      color: ${ColorPalette.white};
      &.bordered {
        border: 1px solid ${ColorPalette.white};
      }
      &.service-item {
        &:hover {
          background-color: ${ColorPalette.white};
          color: ${ColorPalette.black};
        }
      }
      &:hover {
        background-color: ${ColorPalette.hoverOverlayBlack};
      }
      &:active {
        background-color: ${ColorPalette.pressedOverlayBlack};
      }
    }
  }

  &.small {
    font-size: 21px;
    padding: 16px 32px !important;
    height: auto;
    width: auto;
    align-items: center;
    ${respondTo.xsdesktop`
      font-size: 18px !important;
    `}
    ${respondTo.mobile`
      font-size: 18px !important;
    `}
  }
  
  &.unclickable {
    pointer-events: none;
  }

  &.service-item {
    transition: all 300ms ease-in-out;
    background-color: ${ColorPalette.white};
    color: ${ColorPalette.black};
  }

  ${respondTo.mobile`
      font-size: 18px;
      width: 100%;
      height: auto;
      padding: 18px;
  `}
`;

export const StyledLink = styled(Link)`
  ${ButtonCss}

`;
export const StyledLinkHash = styled(HashLink)`
  ${ButtonCss}
    #main {
    margin-top: 24px !important;
  }
`;

export const Button = styled.a`
  ${ButtonCss}
  &.faq {
    margin-top: 40px;
    max-width: 300px;
  }
  &.disabled {
    pointer-events: none;
  }
  .main {
    margin-top: 24px !important;
  }
`;
export const StyledButton = styled.button`
  ${ButtonCss}
`;
