import styled, { keyframes } from 'styled-components';
import { Flex, Spacing } from '../../../../components/common/styled.components';
import { respondTo } from '../../../../util/breakpoints';

const ToggleAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const DropdownWrapper = styled(Flex)`
  width: 100%;
  border-top: 1px solid black;
  cursor: pointer;
  position: relative;
  > article {
    width: 90%;
    height: auto;
  }
`;
export const DropdownGroupWrapper = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid black;
  transition: all 0.3 ease-in-out;
  margin-bottom: ${Spacing.pt48};
`;
export const ToggleIcon = styled.div`
  font-size: 60px;
  height: 100%;
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 0px;
  font-weight: 100;
  align-self: center;
  text-align: center;
  flex-basis: 0%;
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
  &:focus {
    animation: ${ToggleAnimation} 344ms ease-in;
  }
  &.toggled {
    animation: ${ToggleAnimation} 344ms ease-in;
  }
  ${respondTo.mobile`
    font-size: 48px;
  `}
`;
