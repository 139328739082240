import styled from 'styled-components';
import { ColorPalette } from '../common/styled.components';

export const ModalWrapper = styled.div`
  width: 345px;
  position: fixed;
  bottom: 0px;
  right: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background-color: red;
`;

export const CloseButton = styled.div`
  position: absolute;
  content: '';
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

export const CookieConsentWrapper = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 0px;
  margin: auto;
  background-color: ${ColorPalette.white};
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 122;
`;
